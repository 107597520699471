import React, { useState } from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'
import Slider from 'react-slick'
import '../components/slick/slick.scss';
import '../components/slick/slick-theme.scss';
import Modal from 'react-modal'

const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 450;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export default ({data}) => {
  const [modalIsOpen, setModalIsOpen] = useState(data.allWordpressWpAwardees.edges.map((n, z) => { return false }))

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          // variableWidth: false,
        }
      }
    ],
  };

  function openModal(id) {
    setModalIsOpen(prevItems => {
      let prev = [...prevItems]
      prev[id] = true
      return prev
    })
  }

  function closeModal() {
    setModalIsOpen(prevItems => {
      let prev = prevItems.map(() => false)
      return prev
    })
  }

  const modalStyles = {
    content: {
      top: '58%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 110,
      borderRadius: '8px',
      width: '90%',
      height: '75%',
    }
  }

  Modal.setAppElement('#___gatsby')

  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug}/>
      <Hero mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        :
          null
        }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <Container style={{maxWidth: '700px'}}>
        <div className="contentEditorBlock" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}/>
      </Container>

      <div className="grantSlider">
        <h2 className="title" style={{textAlign: 'center', marginBottom: '100px', color: '#8ac640'}}>Recipients</h2>
        <Slider {...settings}>
          {
            data.allWordpressWpAwardees.edges.map((n, i) => {
              let acfExcerpt = n.node?.acf?.awardee_modal_excerpt !== 'undefined' ? n.node.acf.awardee_modal_excerpt : n.node.content
              return (
                <div>
                  <BackgroundImage tag="div" className="slickSlideBG" fluid={n.node.featured_media.localFile.childImageSharp.fluid}>
                    <div className="slickSlideOverlay">
                      <h4 dangerouslySetInnerHTML={{__html: n.node.title}}/>
                      <h5>Recipient</h5>
                      <div className="bio" dangerouslySetInnerHTML={{ __html: text_truncate(acfExcerpt) }} />
                      <button onClick={() => {openModal(i)}} className="button" style={{display: 'block', marginLeft: 'auto'}}>Read more</button>
                    </div>
                  </BackgroundImage>
                </div>
              )
            })
          }
        </Slider>

        {data.allWordpressWpAwardees.edges.map((n, i) => {
          return (
            <Modal
              isOpen={modalIsOpen[i]}
              onRequestClose={closeModal}
              style={modalStyles}
              contentLabel="Board Member Modal"
            >
              <div className="modalBioWrapper" >
                <div className="modalBioImg">
                  <Img fluid={n.node.featured_media.localFile.childImageSharp.fluid} />
                </div>
                <div className="modalBio">
                  <h4>{n.node.title}</h4>
                  <h5>Recipient</h5>
                  <div className="bio" dangerouslySetInnerHTML={{ __html: n.node.content }} />
                  <button onClick={closeModal} className="button">Close</button>
                </div>
              </div>
            </Modal>
          )
        })}

      </div>

      <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query grantProgramQuery {
  wordpressPage(wordpress_id: {eq: 726}) {
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  allWordpressWpAwardees(filter: {categories: {elemMatch: {slug: {eq: "grant-awardee"}}}}) {
    edges {
      node {
        acf {
          awardee_modal_excerpt
        }
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title
        content
      }
    }
  }
}
`

